import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';

import { IFeaturedItemsItemsCarouselProps } from 'components/FeaturedItems/model.d';

import { getSlidesPerView } from './utils';
import { SLIDE_OFFSET } from './constants';

SwiperCore.use([Pagination]);

const FeaturedItemsCarousel: FC<IFeaturedItemsItemsCarouselProps> = ({ windowWidth, children }) => {
  const slidesPerView = getSlidesPerView(windowWidth);

  return slidesPerView ? (
    <div className="featured-items__carousel">
      <Swiper
        wrapperTag="ul"
        slidesPerView={slidesPerView}
        spaceBetween={SLIDE_OFFSET}
        pagination={{ clickable: true }}
        loop
      >
        {children.map((child, idx) => (
          <SwiperSlide key={`swiper-slide--${idx.toString()}`} tag="li">
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : null;
};

export default FeaturedItemsCarousel;
