import React, { FC } from 'react';
import { graphql } from 'gatsby';

import PageLink from 'common/PageLink';
import GatsbyImage from 'common/GatsbyImage';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { TABLET_BREAKPOINT } from 'utils/constants';

import FeaturedItemsCarousel from './components/FeaturedItemsCarousel';
import { IFeaturedItemsProps, IFeaturedItemProps } from './model';

import './FeaturedItems.scss';

const FeaturedItems: FC<IFeaturedItemsProps> & {
  FeaturedItem: FC<IFeaturedItemProps>;
} = ({ image, imageAlt, items }) => {
  const { windowWidth } = useScreenRecognition();

  return (
    <div className="featured-items">
      <div className="featured-items__container">
        <div className="featured-items__image">
          <GatsbyImage image={image} alt={imageAlt} />
        </div>
        {windowWidth > TABLET_BREAKPOINT ? (
          <ul className="featured-items__list">
            {items.map(({ properties: { image: itemImage, imageAlt: itemImageAlt, link } }) => (
              <li className="featured-items__list-item" key={link.url}>
                <FeaturedItems.FeaturedItem
                  key={link.url}
                  image={itemImage}
                  imageAlt={itemImageAlt}
                  link={link}
                />
              </li>
            ))}
          </ul>
        ) : (
          <FeaturedItemsCarousel windowWidth={windowWidth}>
            {items.map(({ properties: { image: itemImage, imageAlt: itemImageAlt, link } }) => (
              <FeaturedItems.FeaturedItem
                key={link.url}
                image={itemImage}
                imageAlt={itemImageAlt}
                link={link}
              />
            ))}
          </FeaturedItemsCarousel>
        )}
      </div>
    </div>
  );
};

FeaturedItems.FeaturedItem = ({ image, imageAlt, link: { url, name, target } }) => (
  <div className="featured-items__item">
    <GatsbyImage className="featured-items__item-image" image={image} alt={imageAlt} />
    <PageLink className="featured-items__item-link" link={url} ariaLabel={name} target={target}>
      <span>{name}</span>
    </PageLink>
  </div>
);

export const query = graphql`
  fragment FragmentFeaturedItems on TFeaturedItems {
    properties {
      image {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 300, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      imageAlt
      items {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 300, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
          link {
            name
            url
            target
          }
        }
      }
    }
  }
`;

export default FeaturedItems;
